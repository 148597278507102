import React from "react";


export const SwapIndexes = [

  { Name: 'USD/AMERIBOR/1Y', Currency: 'USD', Index:'AMERIBOR', Tenor:'1Y', FixingType:'N/A', Pricing:'OIS', FamilyName:'SOFR', FixingDays:0,Calendar:'US government bond market', DayCounter:'Act/360' },
  { Name: 'USD/SOFR/1Y', Currency: 'USD', Index:'SOFR', Tenor:'1Y', FixingType:'N/A', Pricing:'OIS', FamilyName:'SOFR', FixingDays:0,Calendar:'US government bond market', DayCounter:'Act/360' },
  { Name: 'USD/Libor/3M-IsdaFixAm', Currency: 'USD', Index:'Libor', Tenor:'3M', FixingType:'IsdaFixAm', Pricing:'IRS' },
  { Name: 'USD/Libor/3M-IsdaFixPm', Currency: 'USD', Index:'Libor', Tenor:'3M', FixingType:'IsdaFixPm', Pricing:'IRS' },
  //{ Name: 'GBP/Libor/6M-Isda', Currency: 'GBP', Index:'Libor', Tenor:'6M', FixingType:'Isda', Pricing:'IRS' },
  { Name: 'GBP/SONIA/1Y', Currency: 'GBP', Index:'SONIA', Tenor:'1Y', FixingType:'N/A', Pricing:'OIS',  FamilyName:'SONIA', FixingDays:0, Calendar:'UnitedKingdom::Exchange', DayCounter:'A/365' },
  //{ Name: 'CHF/Libor/6M-Isda', Currency: 'CHF', Index:'Libor', Tenor:'6M', FixingType:'Isda', Pricing:'IRS' },
  //{ Name: 'CHF/SARON/1Y', Currency: 'CHF', Index:'SARON', Tenor:'1Y', FixingType:'N/A', Pricing:'OIS',  FamilyName:'SARON', FixingDays:0, Calendar:'TARGET', DayCounter:'A/360' },
  //{ Name: 'JPY/Libor/6M-IsdaFixAm', Currency: 'JPY', Index:'Libor', Tenor:'6M', FixingType:'IsdaFixAm', Pricing:'IRS' },
  //{ Name: 'JPY/Libor/6M-IsdaFixPm', Currency: 'JPY', Index:'Libor', Tenor:'6M', FixingType:'IsdaFixPm', Pricing:'IRS' },
  //{ Name: 'JPY/TONA/1Y', Currency: 'JPY', Index:'TONA', Tenor:'1Y', FixingType:'N/A', Pricing:'OIS',  FamilyName:'TONA', FixingDays:0, Calendar:'Japan', DayCounter:'A/365' },
  { Name: 'EUR/Libor/6M-IsdaFixA', Currency: 'EUR', Index:'Libor', Tenor:'6M', FixingType:'IsdaFixA', Pricing:'IRS' },
  { Name: 'EUR/Libor/6M-IsdaFixB', Currency: 'EUR', Index:'Libor', Tenor:'6M', FixingType:'IsdaFixB', Pricing:'IRS' },
  { Name: 'EUR/ESTR/1Y', Currency: 'EUR', Index:'ESTR', Tenor:'1Y', FixingType:'N/A', Pricing:'OIS',  FamilyName:'ESTR', FixingDays:0, Calendar:'TARGET', DayCounter:'A/360' },

]


export const SwapIndexTenors = [

    /*'1M','3M',*/'6M', /*'9M',*/ '1Y', '2Y', '3Y', '5Y', '7Y', '10Y', '15Y', '20Y', '30Y'

]
