import logo from './logo.svg';


import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import { useEffect, useState, useRef } from 'react';

import BusinessDatePicker from "./components/BusinessDatePicker";

import ForwardRateSurface from './components/ForwardRateSurface';
import MarketDataPanel from './components/MarketDataPanel';
import IndexPanel from './components/IndexPanel';
import SwapPanel from './components/SwapPanel';
import QuickSetupPanel from './components/QuickSetupPanel';
import CashflowsPanel from './components/CashflowsPanel';
import Results from './components/Results';
import PricerHelper from './components/PricerHelper'
import LogonPanel from './components/LogonPanel'
import SessionHelper from './components/SessionHelper'
import RatesMarketDataPanel from './components/RatesMarketDataPanel'
import AnalyticsPanel from './components/AnalyticsPanel'

import 'react-datepicker/dist/react-datepicker.css';

import { Container, Row, Col } from 'react-bootstrap/';
import Accordion from 'react-bootstrap/Accordion';
import { compareAsc, format, parseISO } from 'date-fns'

import { TailSpin } from  'react-loader-spinner'

import { SwapIndexes, SwapIndexTenors } from './data/SwapIndexes'

import helpers from './components/helpers'

function App() {

  const ratesMarketDataPanelRef = useRef()

  const [valuationDate, setValuationDate] = useState(new Date())
  const [marketData, setMarketData] = useState()
  const [positions, setPositions] = useState()
  const [exposure, setExposure] = useState()
  const [logonResponse, setLogonResponse] = useState()
  const [tradeBooked, setTradeBooked] = useState()
  const [toggle, setToggle] = useState(false)
  const [setupTenor, setSetupTenor] = useState()
  const [pricingDisabled, setPricingDisabled] = useState()
  const [curvePricingData, setCurvePricingData] = useState()

  useEffect(() => {

    PricerHelper.get_market_data((data) =>
    {

      var swap_data_out = {};

      data.data.swap_data.forEach((curve_date, i) => {
          var curve_name = curve_date['curve_name']
          var rates = curve_date['rates']

          if ( swap_data_out[curve_name] == undefined )
          {
            swap_data_out[curve_name] = {}
          }

          swap_data_out[curve_name][curve_date['price_date']] = rates
        }
      )
      setMarketData(swap_data_out)
    })

  }, [])

  useEffect(() => {

   if ( logonResponse != undefined )
   {
       var position_request = {}
       position_request = {}
       position_request['email'] = logonResponse['email']
       position_request['jti'] = logonResponse['jti']

       SessionHelper.get_positions(position_request, (positions_in) =>
         {
           if ( positions_in !== undefined && positions_in.positions !== undefined )
           {
             var positions_out = {}
             positions_in.positions.forEach((position, i) => {
               positions_out[position['symbol']] = position
             })

             setPositions(positions_out)
           }
         })
       }
  }, [logonResponse, tradeBooked]);

  return (
    <div style={{width:'100%'}}>
      <nav>
        <Row style={{width:'100%'}}>
          <Col>
          <h3 className="nav--logo_text">
<div style={{padding:'10px'}}>
  Swap Designer
  <div style={{display: 'flex', justifyContent: 'left', fontSize:'14px', fontWeight:'500', paddingTop:'0px'}}>
    <a className="App-link"  href="mailto:mike.kipnis@gmail.com">Support</a>
  </div>
</div>
</h3>
          </Col>
          <Col  style={{marginTop:'5px', float:'right', width:'600px', textAlign: 'right', float:'right', justifyContent:'right'}}>
                <LogonPanel valuationDate={valuationDate}
                  setBusinessDateCallBack={ (date) => { setValuationDate( parseISO(date) ) } }
                  LogonResponseReponseCallBack={ ( logonResponse ) => { console.log(logonResponse) }}/>
            </Col>
          </Row>
      </nav>
      <div className="ag-theme-balham-dark">
        <Container fluid>
          <div style={{marginLeft:'0px'}}>
          <Accordion defaultActiveKey={['0','1']} alwaysOpen>
          <Accordion.Item eventKey='0'>
            <Accordion.Header>Market Data</Accordion.Header>
              <Accordion.Body>
                <RatesMarketDataPanel marketData={marketData}
                  businessDate={valuationDate}
                  pricingDisabledCallBack = { (message) => { setPricingDisabled() } }
                  setupTenorCallBack = { (tenor) => { setSetupTenor(tenor) } }
                  curvePricingDataCallBack = { (curvePricingData) => { setCurvePricingData(curvePricingData) } }
                  ref={ratesMarketDataPanelRef}/>
            </Accordion.Body>
            </Accordion.Item>
          <Accordion.Item eventKey='1'>
            <Accordion.Header>Analytics</Accordion.Header>
              <Accordion.Body>
                <AnalyticsPanel
                  pricingDisabledCallBack = { (message) => { setPricingDisabled() } }
                  curvePricingData={curvePricingData}
                  setupTenor={setupTenor}/>
            </Accordion.Body>
            </Accordion.Item>
            </Accordion>
              </div>
        </Container>
      </div>
    </div>
  );
}

export default App;
