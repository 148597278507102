import React from 'react'
import { DayCounter, BusinessDateConventions, Frequency, DateGen } from '../data/Constants'
import { useEffect, useState } from 'react';
import Dropdown from "./Dropdown";
import BusinessDatePicker from "./BusinessDatePicker";
import { compareAsc, format, parseISO } from 'date-fns'

import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


const SwapLegPanel = React.forwardRef ((props, ref) => {


  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const [dayCounter, setDayCounter] = useState();
  const [busDayConv, setBusDayConv] = useState();
  const [frequency, setFrequency] = useState();
  const [dateGen, setDateGen] = useState();

  const handleSetup = (elementName, value) =>
  {
    if ( props.handleSetup !== undefined && props.termStructure !== undefined )
      props.handleSetup(props.leg_name, elementName, value);
  };

  useEffect(() => {

    if ( props.termStructure !== undefined && props.leg_name !== undefined )
    {

      var schedule = props.termStructure[props.leg_name]

      if ( schedule === undefined || schedule.start === undefined || schedule.end === undefined )
        return


      setStartDate( parseISO(schedule.start) );
      setEndDate( parseISO(schedule.end) );

      setDayCounter(schedule.day_counter);
      setBusDayConv(schedule.bdc);
      setFrequency(schedule.frequency);
      setDateGen(schedule.gen_rule);
    }

  },  [props.termStructure, props.leg_name]);

  return (
    <div>
    {
      props.princingLayout != "OIS" ?
     <Container>
        <Row>
          <Col style={{fontSize: '18px', textAlign:'center', margin:'5px'}}>
            {props.name}
          </Col>
        </Row>
       <Row  style={{marginBottom:'10px'}}>
        <Col style={{margin:'10px'}}>
            <BusinessDatePicker label='Start Date' elementName='start' selected_date={startDate} onValueChange={handleSetup}/>
            <BusinessDatePicker label='End Date' elementName='end' selected_date={endDate} onValueChange={handleSetup}/>
            <Dropdown label='DayCounter' elementName='day_counter' options={DayCounter} selected_value={dayCounter} onValueChange={handleSetup}/>
            <Dropdown label='Bus.Day.Conv.' elementName='bdc' options={BusinessDateConventions} selected_value={busDayConv} onValueChange={handleSetup}/>
            <Dropdown label='Frequency' elementName='frequency' options={Frequency} selected_value={frequency} onValueChange={handleSetup}/>
            <Dropdown label='DateGen' elementName='gen_rule' options={DateGen} selected_value={dateGen} onValueChange={handleSetup}/>
        </Col>
         </Row>
      </Container> :
      <Container>
         <Row>
           <Col style={{fontSize: '18px', textAlign:'center', margin:'5px'}}>
             {props.name}
           </Col>
         </Row>
         <Row>
         <Col style={{margin:'10px'}}>
             <BusinessDatePicker label='Start Date' elementName='start' selected_date={startDate} onValueChange={handleSetup}/>
             <BusinessDatePicker label='End Date' elementName='end' selected_date={endDate} onValueChange={handleSetup}/>
         </Col>
           <Col style={{margin:'10px'}}>
               <Dropdown label='DayCounter' elementName='day_counter' options={DayCounter} selected_value={dayCounter} onValueChange={handleSetup}/>
               <Dropdown label='Bus.Day.Conv.' elementName='bdc' options={BusinessDateConventions} selected_value={busDayConv} onValueChange={handleSetup}/>
               <Dropdown label='Frequency' elementName='frequency' options={Frequency} selected_value={frequency} onValueChange={handleSetup}/>
               <Dropdown label='DateGen' elementName='gen_rule' options={DateGen} selected_value={dateGen} onValueChange={handleSetup}/>
           </Col>
          </Row>

       </Container>

    }
    </div>
    )
 });



export default SwapLegPanel;
