import DatePicker from "react-datepicker";

import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import React from 'react'
import { useEffect, useState } from 'react';
import { compareAsc, format, parseISO } from 'date-fns'

const BusinessDatePicker = React.forwardRef ((props, ref) => {

  const [selectedDate, setSelectedDate] = useState(0);

  useEffect(() =>
  {
    setSelectedDate(props.selected_date);
  }, [props.selected_date]);

  return (
    <Container style={{marginTop:'5px', align:'right'}}>
      <Row>

      <Col style={{textAlign: 'right', float:'right'}}>
        <div style={{justifyContent:'right'}}>
          {props.label}
        </div>
      </Col>

      <Col  style={{textAlign: 'left', float:'left'}}>
        <DatePicker
        highlightDates={props.curve_dates}
        selected={selectedDate} onChange ={(date)=>
          {
            setSelectedDate(date);
            props.onValueChange(props.elementName, format(date, "yyyy-MM-dd"));
          }
        }
        />
      </Col>

      </Row>
      </Container>
    )
 });

export default BusinessDatePicker;
