import { useEffect, useState } from 'react';
import React from 'react';
import { SwapIndexes } from '../data/SwapIndexes'

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham-dark.css';

import uuid from 'react-uuid'
import helpers from './helpers'
import PricerHelper from './PricerHelper'

import {AgGridColumn, AgGridReact} from 'ag-grid-react';

const { forwardRef, useRef, useImperativeHandle } = React;

export const SwapTenors = [
    '1Y', '2Y', '3Y', '5Y', '7Y', '10Y', '15Y', '20Y', '30Y'
]

export const ForwardStartTenors = [
    '0D', '1M', '2M', '3M', '6M', '9M', '1Y', '18M', '2Y', '3Y', '5Y', '10Y', '15Y', '30Y'
]

const ForwardRateMatrix = React.forwardRef ((props, ref) => {

  const forwardRateMatrixGridRef = React.useRef();

  const [columnDefs, setColumnDefs] = useState();
  const [forwardCurve, setForwardCurve] = useState();

  const forwardRateMatrixGridOptions = {

    rowSelection: 'single',

    getRowHeight: (params) => 25,

    onCellClicked: (event: CellClickedEvent) => {

        var tenor = {}

        var setup_tenor = {}
        setup_tenor['fwdstart'] = event.colDef.field
        setup_tenor['tenor'] = event.data.tenor;
        /*console.log(tenor)
        console.log(curvePricingData)

        const updateCurvePriceObject = {...curvePricingData}

        updateCurvePriceObject['tenor'] = tenor*/
        props.setupTenorCallBack(setup_tenor)

        //console.log('Cell was clicked' + event.colDef.field )
    },
  }

  const reprice_curve = ( business_date, swap_index, market_data ) =>
  {

    if ( swap_index ===undefined || market_data === undefined )
      return

    var price_request_out = {};

    price_request_out["request_id"] = uuid();
    price_request_out["operation"] = "FORWARD_RATE_PRICER";
    price_request_out["business_date"] = business_date;
    price_request_out["fixing_type"] = swap_index.FixingType;

    price_request_out["index"] = helpers.get_index_object(price_request_out, swap_index );
    helpers.populate_curve_object(price_request_out, swap_index, market_data);

    price_request_out["swap_tenors"] = SwapTenors;
    price_request_out["forward_tenors"] = ForwardStartTenors;

    props.pricing_start();

    //setPricing(true);

    var results = PricerHelper.submit_request(price_request_out, (pricingResults) =>
    {
      pricingResults["swap_tenors"] = SwapTenors;
      pricingResults["forward_tenors"] = ForwardStartTenors;
      props.Pricing_callback(pricingResults);

      setForwardCurve(pricingResults.forward_rates);
    });
  };

  useEffect(() => {

      if ( props.curvePricingData == undefined )
        return;

      var forward_rate_matrix = [];

      var swap_tenors = {};
      swap_tenors['children'] = [{field:'tenor',headerName:'Swap Tenor', width:100,pinned: 'left'}];

      var forward_rates = {};
      forward_rates['headerName'] = 'Forward Start';
      forward_rates['children']=[];

      for (const [forward_tenor] of Object.entries(ForwardStartTenors))
      {
        var forward_tenor_field = {};
        forward_tenor_field['field'] = ForwardStartTenors[forward_tenor];
        forward_tenor_field['width'] = 60;

        forward_tenor_field['valueFormatter']=(params) =>
          {
            if ( typeof parseFloat(params['value']) === 'number' )
            {
              params['value'] = params['value'] * 100;
              return params['value'].toFixed(3);
            }
          };

        forward_rates['children'].push(forward_tenor_field);
      }

      forward_rate_matrix.push(swap_tenors);
      forward_rate_matrix.push(forward_rates);


      setColumnDefs(forward_rate_matrix);


      reprice_curve(props.curvePricingData.business_date, props.curvePricingData.index, props.curvePricingData.forward_curve);


    }, [props.curvePricingData]);


   return (
      <div>
        <div className="App-Row">
            <div className="ag-theme-balham-dark" style={{verticalAlign:"top",height:"34vh", width: "100%",
            display: "inline-block", margin: "auto"}}>
            <AgGridReact
                      rowData={forwardCurve}
                      //rowData={Indexes}
                      columnDefs={columnDefs}
                      gridOptions={forwardRateMatrixGridOptions}
                      ref={forwardRateMatrixGridRef}>
            </AgGridReact>
            </div>
          </div>
      </div>
     )
  });

export default ForwardRateMatrix;
