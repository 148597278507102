import React from 'react'
import Plot from 'react-plotly.js';

import { useEffect, useState } from 'react';

//import Helpers from './Helpers';


const { forwardRef, useRef, useImperativeHandle } = React;

const ForwardRateSurface = React.forwardRef ((props, ref) => {

  const chartRef = useRef();

  const [data, setData] = useState();
  const [layout, setLayout] = useState();

  useEffect(() => {

    if ( props.forward_rate_matrix != undefined )
    {

      var forward_rates = [];
      props.forward_rate_matrix.forward_rates.forEach(
          swap_tenor_forward_rates =>
            {
              var tenor_forward_rates = [];
              props.forward_rate_matrix.forward_tenors.forEach( forward_tenor =>
              {
                var forward_rate = swap_tenor_forward_rates[forward_tenor];
                tenor_forward_rates.push((parseFloat(forward_rate)*100.0).toFixed(3));
              });
              forward_rates.push(tenor_forward_rates);
            }
        );


      const surface_data = [
        {
          x: props.forward_rate_matrix.forward_tenors,
          y: props.forward_rate_matrix.swap_tenors,
          z: forward_rates,
          hovertemplate: 'Forward Start:%{x}<br>Tenor:%{y}<br>Fair Rate: %{z:.2f}<extra></extra>',
          type: 'surface',
          opacity: 0.8,
          contours: {
   z: {
     show:true,
     label:'test',
     usecolormap: true,
     highlightcolor:"#42f462",
     project:{z: true}
   }
 },
        }
      ];

      setData(surface_data);

      var layout = {
            autosize:false, margin:{ l:50,r:0,b:20,t:50 },
                                    height: 400,
                                    title: props.swapIndex.Name,
                                    width: 800,  scene: {
                                    xaxis: {title:"Forward Start"},
                                    yaxis: {title:"Swap Tenor"},
                                    zaxis: {title:"Fair Rate"} },
};

  setLayout(layout);

};

  }, [props.forward_rate_matrix]);


  return(
      <div style={{display: "block", marginLeft: "auto", marginRight: "auto", paddingLeft:'50px', padding:'25px', borderRadius:'25px'}}>
      <Plot layout={layout} data={data}/>
      </div>
    );
});

export default ForwardRateSurface;
