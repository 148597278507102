import React from 'react'
import { useEffect, useState, useRef } from 'react';

import uuid from 'react-uuid'

import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import helpers from './helpers'
import PricerHelper from './PricerHelper'
import QuickSetupPanel from './QuickSetupPanel'
import SwapPanel from './SwapPanel'
import CashflowsPanel from './CashflowsPanel'
import ForwardRateSurface from './ForwardRateSurface'
import ForwardRateMatrix from './ForwardRateMatrix'

import Accordion from 'react-bootstrap/Accordion';


const AnalyticsPanelSwaps = React.forwardRef ((props, ref) => {

  const quickSetupRef = useRef()
  const swapPanelRef = useRef()

  const [pricingDisabled, setPricingDisabled] = useState()
  const [swapPricingResults, setSwapPricingResults] = useState()
  const [error, setError] = useState()
  const [index, setIndex] = useState()
  const [pricingLayout, setPricingLayout] = useState('IRS')

  const [forwardRateMatix, setForwardRateMatrix] = useState()

  const [setupTenor, setSetupTenor] = useState()



  useEffect( () => {

    if ( props.curvePricingData !== undefined )
    {
      setSwapPricingResults(undefined)
      setIndex(props.curvePricingData.index)
      setPricingLayout(props.curvePricingData.index.Pricing)
    }

  },[props.curvePricingData])

  useEffect( () => {

    if ( props.setupTenor !== undefined )
    {
      setSetupTenor(props.setupTenor)
    }

  },[props.setupTenor])



/*  useEffect(() => {

    if (swapPricingResults !== undefined && props.swapPricingResultsCallBack !== undefined )
    {
        props.swapPricingResultsCallBack(swapPricingResults)
    }

}, [swapPricingResults]);*/

  return (
    <div  style={pricingDisabled ? {pointerEvents: "none", opacity: "0.4"} : {}}>
      <Row>
        <Col>
        <Accordion defaultActiveKey={['0','2']} alwaysOpen>
        <Accordion.Item eventKey='0'>
          <Accordion.Header>Quick Setup</Accordion.Header>
            <Accordion.Body>
          <Row>
          <div className="ag-theme-balham-dark">
            <Col  style={{backgroundColor:'#21222A'}}>
              <QuickSetupPanel curvePricingData={props.curvePricingData} termStructure={swapPricingResults}  setupTenor={setupTenor}
                error={error}
                Pricing_callback={ (pricing_results) => {
                    if ( pricing_results.error_code == 0 )
                    {
                      setSwapPricingResults(pricing_results.my_swap);
                      setError("");
                      setPricingDisabled(false)
                    } else {
                      console.log( "Error : " +  pricing_results.error );
                      setError(pricing_results.error);
                      setPricingDisabled(true)
                    }

                    setPricingDisabled(false);
                }}

                pricing_start = { () => {
                  setPricingDisabled(true);
                }}

                ref={quickSetupRef}/>
            </Col>
            </div>
          </Row>
          </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey='1'>
            <Accordion.Header>Termstructure</Accordion.Header>
              <Accordion.Body>
          <div className="ag-theme-balham-dark">
          <Row style={{margin:'0px'}} >
          <Col style={{backgroundColor:'#21222A'}}>
            <SwapPanel curvePricingData={props.curvePricingData}
              termStructure={swapPricingResults} pricingLayout={pricingLayout}
              Pricing_callback={ (pricing_results) => {
                if ( pricing_results.error_code == 0 )
                {
                  setSwapPricingResults(pricing_results.my_swap);
                  setError("");
                  setPricingDisabled(false)
                } else {
                  console.log( "Error : " +  pricing_results.error );
                  setError(pricing_results.error);
                  setPricingDisabled(true)
                }
                setPricingDisabled(false);
              } }
              pricing_start = { () => { setPricingDisabled(true); }}
              ref={swapPanelRef}/>
              </Col>
          </Row>
          </div>
          {/*
          <div className="main--sub--title" style={{display: 'flex', justifyContent: 'center'}}>
            (Change Notional, Rate or Spread and hit Enter to re-price)
          </div>
          <div style={{display: 'flex', justifyContent: 'center'}}>
            <a className="App-link"  href="mailto:mike.kipnis@gmail.com">Support</a>
          </div>
          */}
          </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey='2'>
            <Accordion.Header>Cashflow</Accordion.Header>
              <Accordion.Body>
          <Row>
            <CashflowsPanel termStructure={swapPricingResults} results={swapPricingResults}/>
          </Row>
          </Accordion.Body>
          </Accordion.Item>
          </Accordion>
          </Col>
        <Col>
        <Row>
        <div style={error !== "" || pricingDisabled ? {pointerEvents: "none", opacity: "0.4",  margin: "auto"} : { margin: "auto" }}>
        <Accordion defaultActiveKey={['5']} alwaysOpen>
        <Accordion.Item eventKey='4'>
          <Accordion.Header>Forward Rate Matrix</Accordion.Header>
            <Accordion.Body>
            <ForwardRateMatrix curvePricingData={props.curvePricingData}
              Pricing_callback={ (pricing_results) => {
                if ( pricing_results.error_code == 0 )
                {
                  setError("");
                  setPricingDisabled(false)
                } else {
                  console.log( "Error : " +  pricing_results.error );
                  setError(pricing_results.error);
                  setPricingDisabled(true)
                }
                setForwardRateMatrix(pricing_results);
                setPricingDisabled(false);
                //setForwardCurvePricingData(undefined)
              }} pricing_start = { () => { setPricingDisabled(true);}}
              setupTenorCallBack = { (tenor) => { setSetupTenor(tenor) } }/>
              </Accordion.Body>
            </Accordion.Item>
        <Accordion.Item eventKey='5'>
          <Accordion.Header>Forward Rate Surface</Accordion.Header>
            <Accordion.Body>
                <ForwardRateSurface swapIndex={index} forward_rate_matrix={forwardRateMatix}/>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
        </Row></Col>
      </Row>
    </div>
    )
 });



export default AnalyticsPanelSwaps;
