import React from 'react'
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS } from 'chart.js/auto';
import { Chart }            from 'react-chartjs-2';
import { useEffect, useState } from 'react';

//import Helpers from './Helpers';


const { forwardRef, useRef, useImperativeHandle } = React;

const CurveChart = React.forwardRef ((swapCurve, ref) => {

  const chartRef = useRef();

  const [displayDiscountCurve, setDisplayDiscountCurve] = useState(false);

  const [data, setData] = useState({
    labels: [],
    datasets: [],
  });

  const [options, setOptions] = useState({
  labels: [],
  datasets: [],
  });


  useEffect(() => {

          var labels = [];
          var rates = [];
          var discount_rates = [];
          var name = "N/A";

          var swap_curve = {}

          if ( swapCurve != undefined && swapCurve.swapCurve !== undefined) {

            //var curve_date = 0;
            name = swapCurve.swapIndex.Name;

            for (const [key, value] of Object.entries(swapCurve.swapCurve.curve_points))
            {
              rates.push((value * 100.0).toFixed(2));
              discount_rates.push((swapCurve.swapCurve.discount_points[key]*100.0).toFixed(2))
              labels.push(key);
            }

          }

          //var name = swapCurve.swapCurve.swapIndex.Name;


          const chart_data = {
            labels: labels,
            datasets: [ { data : rates,fill: true,
            backgroundColor: "rgba(75,192,192,0.2)",
            borderColor: "rgba(75,192,192,1)",
            label : name}]
          };

          if (displayDiscountCurve == true )
          {
            chart_data['datasets'].push( {
              data : discount_rates,
              borderColor: 'rgb(255, 99, 132)',
              label : 'Discount Curve' } );
          }

          const options = {
  plugins: {
    title: {
      display: true,
      align: 'center',
      text: swapCurve.curveDataISO,
      position: 'right',
      color:"rgba(75,192,192,1)"
    },
  }
};

          setOptions(options);
          setData(chart_data);

  }, [swapCurve, displayDiscountCurve]);


  return (
      <div>
        <div>
          <Line options={options} data={data} ref={chartRef}/>
        </div>
        <div style={{display:'flex', flexDirection:'row', justifyContent:'right', alignItems:'right', paddingRight:'10px'}}>
            <div>
           <input type="checkbox"
             checked={displayDiscountCurve}
             onChange={ () =>
              {
                setDisplayDiscountCurve(!displayDiscountCurve);
                console.log("Checked");
              }}
           />
           </div>
           <div style={{paddingLeft:'5px'}}>
            Discount Curve
            </div>
         </div>
      </div>
    )
});

export default CurveChart;
