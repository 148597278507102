import { useEffect, useState } from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { TailSpin } from  'react-loader-spinner'
import BusinessDatePicker from "./BusinessDatePicker";

import SessionHelper from './SessionHelper'

import React from 'react'

import { jwtDecode } from "jwt-decode";

import { GoogleLogin, GoogleOAuthenProvider, useGoogleLogin} from '@react-oauth/google';
import { GoogleOAuthProvider } from '@react-oauth/google';


const { forwardRef, useRef, useImperativeHandle } = React;

const LogonPanel = React.forwardRef ((props, ref) => {

  const [userCredentials, setUserCredentials] = useState();

  const responseMessage = (response) => {

    const USER_CREDENTIAL = jwtDecode(response.credential);
    setUserCredentials(USER_CREDENTIAL);

  };

  useEffect(() => {

  if (userCredentials !== undefined && props.LogonResponseReponseCallBack !== undefined )
  {
    console.log(userCredentials);

    SessionHelper.submit_login(userCredentials, (logon_response) => {
      props.LogonResponseReponseCallBack(logon_response['data'])
    });
    //setPositions(undefined);
    //setExposure(undefined);
  }

}, [userCredentials, props]);

  const errorMessage = (error) => { console.log(error); };

  const logged_in_div =
  <div style={{textAlign:'right', paddingTop:'5px'}}>
    <Row>
      <Col>
        {userCredentials == undefined ? null : userCredentials.name}
      </Col>
    </Row>
  <Row>
    <Col style={{fontSize:'10px'}}>
      {userCredentials == undefined ? null : userCredentials.email}
    </Col>
  </Row>
</div>

  return (

    <div style={{marginTop:'5px', float:'right', width:'400px', textAlign: 'right', float:'right', justifyContent:'right'}}>
      <Row>
        <Col style={{textAlign: 'left', paddingRight:'30px'}}>
          <div className="ag-theme-balham-dark" style={{verticalAlign:"top",height:"4vh", width: "100%", display: "inline-block",
            margin: "auto", padding:"10px", textAlign:'right', fontWeight: 700, fontSize:'14px'}}>
          {
            props.pricingDisabled == true ?
              <TailSpin height="35" width="35" color='#61dafb' ariaLabel='pricing'/>
              : <BusinessDatePicker label='Valuation Date' elementName='business_date' selected_date={props.valuationDate}
                    onValueChange={(elementName, date) => { props.setBusinessDateCallBack(date) } }/>
          }
          </div>
        </Col>
        {/*
        <Col xs={4}>
          <Row>
          <Col xs={9} style={{text:'nowrap',justifyContent:'right', textAlign:'right', color:'white', paddingRight:'40px'}}>
          {
            userCredentials == undefined ? <div style={{paddingTop:'10px'}}>Sign-in with</div> : logged_in_div
          }
          </Col>
          <Col xs={2} style={{paddingTop:'10px', paddingRight:'20px',justifyContent:'right', textAlign:'right', float:'right'}}>
          <GoogleOAuthProvider clientId="66097256498-ifgfagl8lb2n2sv95n47d9r19qatjipc.apps.googleusercontent.com">
            <div>
              <GoogleLogin onSuccess={responseMessage} onError={errorMessage} theme='filled_black' size='medium' type='icon'/>
            </div>
          </GoogleOAuthProvider>
          </Col>
          </Row>
        </Col>
        */}
      </Row>
    </div>
    )
 });

export default LogonPanel;
