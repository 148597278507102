import React from 'react'
import { useEffect, useState } from 'react';

import uuid from 'react-uuid'

import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import helpers from './helpers'
import PricerHelper from './PricerHelper'
import AnalyticsPanelSwaps from './AnalyticsPanelSwaps'

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

const AnalyticsPanel = React.forwardRef ((props, ref) => {

  const [panel, setPanel] = useState()

  return (
      <div>
          <AnalyticsPanelSwaps curvePricingData={props.curvePricingData} setupTenor={props.setupTenor}/>
     </div>
    )
 });



export default AnalyticsPanel;
