import { useEffect, useState } from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import React from 'react'

const { forwardRef, useRef, useImperativeHandle } = React;

const Dropdown = React.forwardRef ((props, ref) => {

      const handleChange = (event) => {

      if ( props.onValueChange !== undefined && event.target.value !== undefined )
      {
        props.onValueChange(props.elementName, event.target.value);
      }

    };

  return (
    <Container style={{marginTop:'5px'}}>
      <Row>

      <Col style={{textAlign: 'right'}}>
          {props.label}
      </Col>
      <Col style={{textAlign:'left'}}>
      <select  style={ props.width_in !== undefined ? {width:props.width_in}: {width:'140px'} } value={props.selected_value} onChange={handleChange} ref={ref} >
        {
            props.options.map((option) => (
                <option value={option}>{option}</option>
              ))
        }
      </select>
      </Col>
      </Row>
      </Container>
  )
  });

export default Dropdown;
